import firebase from '../lib/firebase/client';
import { fromDateToTimestamp } from '../lib/dateHandler';
import { isBoolean, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';

export class Category extends FirebaseModel {
  name?: string;

  src?: string;

  selected?: boolean;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.name = '';
      this.src = '';
      this.selected = false;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.name = isString(doc?.name) ? doc?.name ?? null : null;
    this.src = isString(doc?.src) ? doc?.src ?? null : null;
    this.selected = isBoolean(doc?.selected) ? doc?.selected ?? false : false;
  }

  static factory(doc?: any): Category | null {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Category(doc);
  }

  static toFirebaseFormat(self: Category) {
    return {
      name: self?.name ?? null,
      src: self?.src ?? null,
      selected: self?.selected ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async create(category: Partial<Category>): Promise<Category> {
    const ref = await firebase
      .firestore()
      .collection('/categories')
      .add({
        id: '',
        name: category?.name ?? null,
        src: category?.src ?? null,
      });
    await ref.update({
      id: ref?.id ?? null,
      name: category?.name ?? null,
      src: category?.src ?? null,
    });
    return Category.factory({ ...category, id: ref.id });
  }

  static async update(category: Partial<Category>): Promise<void> {
    await firebase
      .firestore()
      .collection('/categories')
      .doc(`/${category?.id}`)
      .set(
        {
          ...(Category.toFirebaseFormat(category) ?? {}),
        },
        { merge: true }
      );
  }

  static async delete(category: Partial<Category>): Promise<void> {
    const subcategories = (
      await firebase
        .firestore()
        .collection('/subcategories')
        .where('category', '==', category?.id)
        .get()
    ).docs.map((doc) => doc.id);
    const batch = firebase.firestore().batch();
    const categoryRef = firebase
      .firestore()
      .collection('/categories')
      .doc(`/${category?.id}`);
    batch.delete(categoryRef);
    subcategories.forEach((subcategory) => {
      batch.delete(
        firebase.firestore().collection('/subcategories').doc(`/${subcategory}`)
      );
    });
    await batch.commit();
  }
}
