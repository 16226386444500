import { v4 as uuid } from 'uuid';
import { isString } from '../lib/guards';

export class Document {
  id: string;

  src?: string | ArrayBuffer; // url

  name?: string; // name

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    this.id = isString(doc?.id) ? doc?.id : uuid();
    this.src = doc?.src;
    this.name = isString(doc?.name) ? doc?.name : '';
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Document(doc);
  }

  static toFirebaseFormat(self: Partial<Document>) {
    return {
      id: self?.id ?? null,
      src: String(self?.src ?? null),
      name: self?.name ?? null,
    };
  }
}
