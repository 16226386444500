import firebase from '../lib/firebase/client';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { Client } from './Client';
import { ContactPerson } from './ContactPerson';
import { FirebaseModel } from './FirebaseModel';
import { User } from './User';
import { Requirement } from './Requirement';
import { Observation } from './Observation';
import { generateProjectId } from '../lib/firebase/operations/generateId';
import { fromDateToTimestamp, fromStringToDate } from '../lib/dateHandler';
import { Quote } from './Quote';
import { Supplier } from './Supplier';
import { PdfModel } from './pdf';

export class Project extends FirebaseModel {
  static phases = [
    { value: '1', text: '1. Registro del Cuestionario' },
    { value: '2', text: '2. Solicitud proveedores' },
    { value: '3', text: '3. Análisis del Proyecto' },
    { value: '4', text: '4. Creación de Cotización' },
    { value: '5', text: '5. Envío de la Cotización' },
    { value: '6', text: '6. Espera de Aprobación' },
    { value: '7', text: '7. Aprobado / Negociación' },
    { value: '8', text: '8. Análisis del proyecto' },
    { value: '9', text: '9. Registro de Proyecto' },
    { value: '10', text: '10. Producción del proyecto' },
    { value: '11', text: '11. Cierre / Facturación' },
    { value: '12', text: '12. Proyecto por cobrar' },
    { value: '13', text: '13. Proyecto finalizado' },
    { value: '14', text: '14. Cancelado' },
  ] as const;

  static PHASE_TOOLTIP = {
    '1': 'Registro del Cuestionario',
    '2': 'Solicitud proveedores',
    '3': 'Análisis del Proyecto',
    '4': 'Creación de Cotización',
    '5': 'Envío de la Cotización',
    '6': 'Espera de Aprobación',
    '7': 'Aprobado / Negociación',
    '8': 'Análisis del proyecto',
    '9': 'Registro de Proyecto',
    '10': 'Producción del proyecto',
    '11': 'Cierre / Facturación',
    '12': 'Proyecto por cobrar',
    '13': 'Proyecto finalizado',
    '14': 'Cancelado',
  } as const;

  static STATUS_TEXT = {
    '1': 'Tentativo',
    '2': 'Aprobado',
    '3': 'Ejecutado',
    '4': 'Finalizado',
    '5': 'Cancelado',
  } as const;

  static STATUS = {
    TENTATIVE: 1,
    APPROVED: 2,
    EXECUTED: 3,
    DONE: 4,
    CANCELLED: 5,
  } as const;

  client?: string | Client;

  project?: string;

  type?: string;

  eventType?: string;

  date?: Date;

  dateLimit?: Date;

  executive?: string | User;

  projectManagers?: Array<string | User>;

  phase?: number;

  status?: number;

  hours?: Array<string>;

  location?: string;

  quantity?: number;

  salesChannel?: string;

  attention?: string | ContactPerson;

  requirements?: Array<Requirement>;

  observations?: Observation;

  cancellation?: string;

  clientType?: string;

  per?: number;

  fee?: number;

  adminFee?: number;

  approved?: boolean;

  contact?: string;

  platform?: string;

  pdf?: PdfModel;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.project = null;
      this.type = null;
      this.eventType = null;
      this.quantity = null;
      this.salesChannel = null;
      this.location = null;
      this.cancellation = null;
      this.contact = null;
      this.platform = null;
      this.per = 0;
      this.fee = 0;
      this.adminFee = 0;
      this.clientType = 'none';
      this.date = null;
      this.dateLimit = null;
      this.client = null;
      this.attention = null;
      this.executive = null;
      this.phase = 1;
      this.status = 1;
      this.hours = null;
      this.projectManagers = null;
      this.requirements = [];
      this.observations = null;
      this.approved = false;
      this.pdf = null;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.project = isString(doc?.project) ? doc?.project ?? null : null;
    this.type = isString(doc?.type) ? doc?.type ?? null : null;
    this.eventType = isString(doc?.eventType) ? doc?.eventType ?? null : null;
    this.quantity = isNumber(doc?.quantity) ? doc?.quantity ?? null : null;
    this.salesChannel = isString(doc?.salesChannel)
      ? doc?.salesChannel ?? null
      : null;
    this.location = isString(doc?.location) ? doc?.location ?? null : null;
    this.cancellation = isString(doc?.cancellation)
      ? doc?.cancellation ?? null
      : null;
    this.contact = isString(doc?.contact) ? doc?.contact ?? null : null;
    this.platform = isString(doc?.platform) ? doc?.platform ?? null : null;
    this.per = isNumber(doc?.per) ? doc?.per ?? 0 : 0;
    this.fee = isNumber(doc?.fee) ? doc?.fee ?? 0 : 0;
    this.adminFee = isNumber(doc?.adminFee) ? doc?.adminFee ?? 0 : 0;
    this.clientType = isString(doc?.clientType)
      ? doc?.clientType ?? null
      : 'none';
    this.date = isTimestamp(doc?.date) ? doc?.date?.toDate() : new Date();
    this.dateLimit = isTimestamp(doc?.dateLimit)
      ? doc?.dateLimit?.toDate()
      : new Date();
    this.client = isString(doc?.client)
      ? doc?.client
      : Client.factory(doc?.client);
    this.attention = isString(doc?.attention)
      ? doc?.attention ?? null
      : ContactPerson.factory(doc?.attention);
    this.executive = isString(doc?.executive)
      ? doc?.executive
      : User.factory(doc?.executive);
    this.phase = isNumber(doc?.phase) ? doc?.phase : null;
    this.status = isNumber(doc?.status) ? doc?.status : null;
    this.hours = Array.isArray(doc?.hours)
      ? doc?.hours?.map((hour) => (isString(hour) ? hour : '')) ?? []
      : [];
    this.projectManagers = Array.isArray(doc?.projectManagers)
      ? doc?.projectManagers?.map((pm) =>
          isString(pm) ? pm : User.factory(pm)
        ) ?? []
      : [];
    this.requirements = Array.isArray(doc?.requirements)
      ? doc?.requirements?.map((requirement) =>
          Requirement.factory(requirement)
        ) ?? []
      : [];
    this.observations = Observation.factory(doc?.observations);
    this.approved = isBoolean(doc?.approved) ? doc?.approved ?? false : false;
    this.pdf = PdfModel.factory(doc?.pdf, this);
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Project(doc);
  }

  static toFirebaseFormat(self: Partial<Project>) {
    return {
      client: isString(self?.client)
        ? self?.client ?? null
        : self?.client?.id ?? null,
      project: self?.project ?? null,
      type: self?.type ?? null,
      eventType: self?.eventType ?? null,
      date: fromDateToTimestamp(self?.date),
      dateLimit: fromDateToTimestamp(self?.dateLimit),
      executive: isString(self?.executive)
        ? self?.executive ?? null
        : self?.executive?.id ?? null,
      projectManagers: self?.projectManagers?.map((pm) =>
        isString(pm) ? pm ?? null : pm?.id ?? null
      ),
      phase: self?.phase ?? null,
      status: self?.status ?? null,
      hours: self?.hours ?? null,
      location: self?.location ?? null,
      quantity: self?.quantity ?? null,
      salesChannel: self?.salesChannel ?? null,
      attention: isString(self?.attention)
        ? self?.attention ?? null
        : self?.attention?.id ?? null,
      requirements:
        self?.requirements?.map(
          (requirement) => Requirement?.toFirebaseFormat(requirement) ?? null
        ) ?? null,
      observations: Observation.toFirebaseFormat(self?.observations),
      cancellation: self?.cancellation ?? null,
      per: self?.per ?? null,
      fee: self?.fee ?? null,
      adminFee: self?.adminFee ?? null,
      clientType: self?.clientType ?? null,
      approved: self?.approved ?? null,
      contact: self?.contact ?? null,
      platform: self?.platform ?? null,
      pdf: PdfModel.toFirebaseFormat(self?.pdf),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static getContactPerson(self: Partial<Project>): ContactPerson {
    if (isString(self?.attention)) {
      return null;
    }
    return self?.attention ?? null;
  }

  static getQuotes(project: Partial<Project>): Array<Quote> {
    if (project?.requirements) {
      const quotesObject: Record<string, Quote> = {};
      project?.requirements?.forEach((req) => {
        req?.quotes?.forEach((quote) => {
          if (quote.selected) {
            quotesObject[quote?.id] = quote;
          }
        });
      });
      return Object.values(quotesObject);
    }
    return [];
  }

  static getRealCost(
    quotes: Array<Quote>,
    suppliers: Array<Supplier>
  ): [suppliers: number, allies: number] {
    const _realCostSupplier =
      quotes?.reduce((acc, quote) => {
        const supplier = suppliers?.find(
          (_supplier) =>
            (isString(quote?.supplier)
              ? quote?.supplier
              : quote?.supplier?.id ?? null) === _supplier?.id
        );
        const budget =
          quote?.budgets?.find((_budget) => _budget?.id === quote?.budgetId) ??
          null;
        if (supplier?.discount > 0) {
          return acc + 0;
        }
        return (budget?.cost ?? 0) + acc;
      }, 0) ?? 0;
    const _realCostAlly =
      quotes?.reduce((acc, quote) => {
        const supplier = suppliers?.find(
          (_supplier) =>
            (isString(quote?.supplier)
              ? quote?.supplier
              : quote?.supplier?.id ?? null) === _supplier?.id
        );
        const budget =
          quote?.budgets?.find((_budget) => _budget?.id === quote?.budgetId) ??
          null;
        if (supplier?.discount > 0) {
          return (
            (budget?.cost ?? 0) * (1 - (supplier?.discount ?? 0) / 100) + acc
          );
        }
        return acc + 0;
      }, 0) ?? 0;
    return [_realCostSupplier, _realCostAlly];
  }

  static getFee(
    quotes: Array<Quote>,
    suppliers: Array<Supplier>,
    type: string
  ): [service: number, admin: number] {
    const [realCostSupplier, realCostAlly] = Project.getRealCost(
      quotes,
      suppliers
    );
    let serviceFee = 0;
    let adminFee = 0;
    // Como el multiplicador 0 lo representamos con un 1, sumamos todos los multiplicadores.
    // Si la suma es mayor al total de quotes presentes, entonces al menos 1 tiene
    // Un multiplicador distinto de 1, y consecuencia el fee es 0
    const hasMultiplier =
      (quotes?.reduce((acc, quote) => acc + (quote?.multiplier ?? 1), 0) ??
        0) !== quotes?.length;
    // Si tiene multiplicador, no se agrega fee
    if (hasMultiplier) {
      serviceFee = 0;
      adminFee = 0;
    } else {
      // Admin Fee
      if (type === 'Especial') {
        adminFee = (realCostSupplier + realCostAlly) * 0.12;
      } else {
        adminFee = 0;
      }
      // Services Fee
      // Este disabled ayuda a facilitar el entendimiento del código
      // eslint-disable-next-line no-lonely-if
      if (type === 'Especial') {
        // Esto es un fee para aquellos clientes que son contribuyentes especiales
        serviceFee = realCostSupplier * 0.15 + realCostAlly * 0.05;
      } else if (type === 'Ordinario' || type === 'Social') {
        serviceFee = realCostSupplier * 0.15;
      } else {
        // Esto es que aun no se ha escogido un tipo de cliente
        serviceFee = 0;
      }
    }
    return [serviceFee, adminFee];
  }

  static getTotals(project: Partial<Project>, suppliers: Array<Supplier>) {
    const quotes = Project.getQuotes(project);
    const subtotal =
      quotes?.reduce((acc, quote) => {
        if (quote.selected) {
          const budget = quote?.budgets?.find((b) => b?.id === quote?.budgetId);
          return acc + (budget?.cost ?? 0) * (quote?.multiplier ?? 1);
        }
        return acc;
      }, 0) ?? 0;
    const personal = Number(project?.per ?? 0) * 1.25;
    const [serviceFee, adminFee] = Project.getFee(
      quotes,
      suppliers,
      project?.clientType
    );
    const _total =
      Number(serviceFee ?? 0) +
      Number(adminFee ?? 0) +
      Number(subtotal ?? 0) +
      Number(personal ?? 0);
    const total = Number.isNaN(Number(_total)) ? 0 : Number(_total);
    return { subtotal, personal, serviceFee, adminFee, total } as const;
  }

  static async updateStatus(project: Project, update: Partial<Project>) {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(project?.id)
      .set(
        {
          phase: update?.phase ?? null,
          status: update?.status ?? null,
        },
        {
          merge: true,
        }
      );
  }

  static async createFromState(state: any): Promise<string> {
    const id = await generateProjectId(fromStringToDate(state?.date));
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${id}`)
      .set(
        {
          id,
          client: isString(state.client)
            ? state?.client ?? null
            : state?.client?.id ?? null,
          project: state?.project ?? null,
          type: state?.type === 'none' ? null : state?.type ?? null,
          eventType:
            state?.eventType === 'none' ? null : state?.eventType ?? null,
          date: fromDateToTimestamp(fromStringToDate(state?.date)),
          dateLimit: fromDateToTimestamp(fromStringToDate(state?.dateLimit)),
          executive:
            state?.executive !== 'none' ? state?.executive ?? null : null,
          projectManagers: state?.projectManagers ?? [],
          phase: Number(state?.phase || '1'),
          status: 1,
          hours: [state?.initHour ?? null, state?.closeHour ?? null]?.filter(
            (x) => !!x
          ),
          location: state?.location ?? null,
          quantity: Number(state?.quantity ?? '0'),
          salesChannel:
            state?.salesChannel === 'none' ? null : state?.salesChannel ?? null,
          attention: state?.attention ?? null,
          requirements: state?.requirements ?? [],
          observations: {
            general: [],
            suppliers: [],
            hours: [],
          },
          cancellation: null,
          per: 0,
          fee: 0,
          clientType: null,
          approved: false,
          contact: null,
          platform: 'Plataforma Bloopex',
          createdAt: fromDateToTimestamp(new Date()),
          pdf: PdfModel.toFirebaseFormat(new PdfModel({}, {})),
        },
        { merge: true }
      );
    return id;
  }

  static async updateFromState(state: any, id: string): Promise<void> {
    console.log(state);
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${id}`)
      .set(
        {
          id,
          client: isString(state.client)
            ? state?.client ?? null
            : state?.client?.id ?? null,
          project: state?.project ?? null,
          type: state?.type === 'none' ? null : state?.type ?? null,
          eventType:
            state?.eventType === 'none' ? null : state?.eventType ?? null,
          date: fromDateToTimestamp(fromStringToDate(state?.date)),
          dateLimit: fromDateToTimestamp(fromStringToDate(state?.dateLimit)),
          executive: state?.executive ?? null,
          projectManagers: state?.projectManagers ?? [],
          phase: Number(state?.phase ?? '1'),
          status: Number(state?.status ?? '1'),
          hours: [state?.initHour ?? null, state?.closeHour ?? null].filter(
            (x) => !!x
          ),
          location: state?.location ?? null,
          quantity: Number(state?.quantity ?? '0'),
          salesChannel:
            state?.salesChannel === 'none' ? null : state?.salesChannel ?? null,
          attention: state?.attention ?? null,
          cancellation: state?.cancellation ?? null,
          contact: state?.contact ?? null,
        },
        { merge: true }
      );
  }

  static async update(project: Partial<Project>): Promise<void> {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${project?.id}`)
      .set(
        {
          ...(Project.toFirebaseFormat(project) ?? {}),
        },
        {
          merge: true,
        }
      );
  }

  static async deleteMany(ids: string[]): Promise<void> {
    const batch = firebase.firestore().batch();
    ids.forEach((id) => {
      batch.delete(firebase.firestore().collection('/projects').doc(`/${id}`));
    });
    await batch.commit();
  }

  static async updatePersonal(project: Partial<Project>): Promise<void> {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${project?.id}`)
      .set(
        {
          per: Number(project?.per ?? '0'),
        },
        {
          merge: true,
        }
      );
  }

  static async updateClientType(project: Partial<Project>): Promise<void> {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${project?.id}`)
      .set(
        {
          clientType: project?.clientType ?? null,
        },
        {
          merge: true,
        }
      );
  }

  static async approve(project: Partial<Project>): Promise<void> {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${project?.id}`)
      .set(
        {
          approved: true,
        },
        {
          merge: true,
        }
      );
  }
}
