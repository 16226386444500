import firebase from '../lib/firebase/client';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { Client } from './Client';
import { Supplier } from './Supplier';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class User extends FirebaseModel {
  static USER_PRIVILEGE = {
    CLIENT: '0',
    SUPPLIER: '10',
    ADMIN: '100',
    EXECUTIVE: '200',
    PROJECT_MANAGER: '300',
  } as const;

  static USER_PRIVILEGE_TRANSLATE = {
    '0': 'Cliente',
    '10': 'Proveedor',
    '100': 'Administrador',
    '200': 'Ejecutivo de ventas',
    '300': 'Project Manager',
  } as const;

  uid?: string;

  firstName?: string;

  lastName?: string;

  email?: string;

  phone?: string;

  profilePic?: string;

  client?: string | Client;

  supplier?: string | Supplier;

  charge?: string;

  privilege?: number; // 0: client, 100: proveedor, 200: admins

  username?: string;

  type?: string; // ?

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );

    if (typeof doc === 'undefined') {
      this.uid = '';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.profilePic = '';
      this.charge = '';
      this.username = '';
      this.type = '';
      this.client = null;
      this.supplier = null;
      this.privilege = Number(User.USER_PRIVILEGE.EXECUTIVE);
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.uid = isString(doc?.uid) ? doc?.uid ?? null : null;
    this.firstName = isString(doc?.firstName) ? doc?.firstName ?? null : null;
    this.lastName = isString(doc?.lastName) ? doc?.lastName ?? null : null;
    this.email = isString(doc?.email) ? doc?.email ?? null : null;
    this.phone = isString(doc?.phone) ? doc?.phone ?? null : null;
    this.profilePic = isString(doc?.profilePic)
      ? doc?.profilePic ?? null
      : null;
    this.charge = isString(doc?.charge) ? doc?.charge ?? null : null;
    this.username = isString(doc?.username) ? doc?.username ?? null : null;
    this.type = isString(doc?.type) ? doc?.type ?? null : null;
    this.client = isString(doc?.client) ? doc?.client ?? '' : null;
    this.supplier = isString(doc?.supplier) ? doc?.supplier ?? '' : null;
    this.privilege = isNumber(doc?.privilege)
      ? doc?.privilege ?? Number(User.USER_PRIVILEGE.EXECUTIVE)
      : Number(User.USER_PRIVILEGE.EXECUTIVE);
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new User(doc);
  }

  static toFirebaseFormat(self: Partial<User>) {
    return {
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
      uid: self?.uid ?? null,
      firstName: self?.firstName ?? null,
      lastName: self?.lastName ?? null,
      email: self?.email ?? null,
      phone: self?.phone ?? null,
      profilePic: self?.profilePic ?? null,
      charge: self?.charge ?? null,
      username: self?.username ?? null,
      type: self?.type ?? null,
      client: null,
      supplier: null,
      privilege: Number(self?.privilege) ?? null,
    };
  }

  static async updateUser(state: any, id: string): Promise<string> {
    await firebase
      .firestore()
      .collection('/users')
      .doc(id)
      .set(
        {
          id,
          firstName: state?.firstName ?? '',
          lastName: state?.lastName ?? '',
          phone: state?.phone ?? '',
          type: state?.type ?? '',
          profilePic: state?.profilePic ?? '',
        },
        { merge: true }
      );
    return id;
  }
}
