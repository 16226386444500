import { isString } from '../lib/guards';
import { Supplier } from './Supplier';

export class Observation {
  general?: Array<{
    observation: string;
    id: string;
  }>;

  suppliers?: Array<{
    supplier: string | Supplier;
    observation: string;
    id: string;
  }>;

  hours?: Array<{ hour: string; observation: string; id: string }>;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    if (typeof doc === 'undefined') {
      this.general = [];
      this.suppliers = [];
      this.hours = [];
    }
    this.general = Array.isArray(doc?.general)
      ? doc?.general?.map((general) => ({
          observation: isString(general?.observation)
            ? general?.observation ?? ''
            : '',
          id: isString(general?.id) ? general?.id ?? null : null,
        }))
      : [];
    this.suppliers = Array.isArray(doc?.suppliers)
      ? doc?.suppliers?.map((supplier) => ({
          supplier: isString(supplier?.supplier)
            ? supplier?.supplier
            : Supplier.factory(supplier?.supplier),
          observation: isString(supplier?.observation)
            ? supplier?.observation
            : '',
          id: isString(supplier?.id) ? supplier?.id : '',
        }))
      : [];
    this.hours = Array.isArray(doc?.hours)
      ? doc?.hours?.map((hour) => ({
          hour: isString(hour?.hour) ? hour?.hour : '',
          observation: isString(hour?.observation) ? hour?.observation : '',
          id: isString(hour?.id) ? hour?.id : '',
        }))
      : [];
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Observation(doc);
  }

  static toFirebaseFormat(self: Partial<Observation>) {
    return {
      general:
        self?.general?.map((general) => ({
          observation: isString(general?.observation)
            ? general?.observation ?? null
            : null,
          id: isString(general?.id) ? general?.id ?? null : null,
        })) ?? null,
      suppliers:
        self?.suppliers?.map((supplier) => ({
          supplier: isString(supplier?.supplier)
            ? supplier?.supplier ?? null
            : supplier?.supplier?.id ?? null,
          observation: isString(supplier?.observation)
            ? supplier?.observation ?? null
            : null,
          id: isString(supplier?.id) ? supplier?.id ?? null : null,
        })) ?? null,
      hours:
        self?.hours?.map((hour) => ({
          hour: isString(hour?.hour) ? hour?.hour ?? null : null,
          observation: isString(hour?.observation)
            ? hour?.observation ?? null
            : null,
          id: isString(hour?.id) ? hour?.id ?? null : null,
        })) ?? null,
    };
  }
}
