import { isBoolean, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { Category } from './Category';
import { Subcategory } from './Subcategory';
import { Document } from './Document';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class Description extends FirebaseModel {
  category?: Category | string;

  subcategory?: Subcategory | string;

  quantity?: string;

  observations?: string;

  files?: Document[];

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.category = null;
      this.subcategory = null;
      this.quantity = '';
      this.observations = '';
      this.files = [];
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.category = isString(doc?.category)
      ? doc?.category ?? ''
      : Category.factory(doc?.category);
    this.subcategory = isString(doc?.subcategory)
      ? doc?.subcategory ?? ''
      : Subcategory.factory(doc?.subcategory);
    this.quantity = isString(doc?.quantity) ? doc?.quantity ?? null : null;
    this.observations = isString(doc?.observations)
      ? doc?.observations ?? ''
      : '';
    this.files = Array.isArray(doc?.files)
      ? doc?.files?.map((file) => Document.factory(file))
      : [];
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Description(doc);
  }

  static toFirebaseFormat(self: Partial<Description>) {
    return {
      category: isString(self?.category)
        ? self?.category ?? null
        : self?.category?.id ?? null,
      subcategory: isString(self?.subcategory)
        ? self?.subcategory ?? null
        : self?.subcategory?.id ?? null,
      quantity: self?.quantity ?? null,
      observations: self?.observations ?? null,
      files:
        self?.files?.map((file) => Document.toFirebaseFormat(file)) ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
