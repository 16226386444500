import { fromDateToTimestamp } from '../lib/dateHandler';
import { isBoolean, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';

export class Contact extends FirebaseModel {
  project?: string;

  companyName?: string;

  firstName?: string;

  lastName?: string;

  email?: string;

  phone?: string;

  deliveryAddress?: string;

  date?: Date;

  dateLimit?: Date;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.project = '';
      this.companyName = '';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.deliveryAddress = '';
      this.date = null;
      this.dateLimit = null;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : null;
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : null;
    this.project = isString(doc?.project) ? doc?.project ?? null : null;
    this.companyName = isString(doc?.companyName)
      ? doc?.companyName ?? null
      : null;
    this.firstName = isString(doc?.firstName) ? doc?.firstName ?? null : null;
    this.lastName = isString(doc?.lastName) ? doc?.lastName ?? null : null;
    this.email = isString(doc?.email) ? doc?.email ?? null : null;
    this.phone = isString(doc?.phone) ? doc?.phone ?? null : null;
    this.deliveryAddress = isString(doc?.deliveryAddress)
      ? doc?.deliveryAddress ?? ''
      : '';
    this.date = isTimestamp(doc?.date) ? doc?.date?.toDate() : new Date();
    this.dateLimit = isTimestamp(doc?.dateLimit)
      ? doc?.dateLimit?.toDate()
      : new Date();
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Contact(doc);
  }

  static toFirebaseFormat(self: Partial<Contact>) {
    return {
      project: self?.project ?? null,
      companyName: self?.companyName ?? null,
      firstName: self?.firstName ?? null,
      lastName: self?.lastName ?? null,
      email: self?.email ?? null,
      phone: self?.phone ?? null,
      deliveryAddress: self?.deliveryAddress ?? null,
      date: fromDateToTimestamp(self?.date),
      dateLimit: fromDateToTimestamp(self?.dateLimit),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
