import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { Document } from './Document';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class Budget extends FirebaseModel {
  code?: string;

  deliveredDate?: Date;

  cost?: number;

  files?: Array<Document>;

  photos?: Array<Document>;

  selected?: boolean;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.code = '';
      this.deliveredDate = new Date();
      this.cost = 0;
      this.files = [];
      this.photos = [];
      this.selected = false;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.code = isString(doc?.code) ? doc?.code : '';
    this.deliveredDate = isTimestamp(doc?.deliveredDate)
      ? doc?.deliveredDate?.toDate()
      : new Date();
    this.cost = isNumber(doc?.cost) ? doc?.cost ?? 0 : 0;
    this.files = Array.isArray(doc?.files)
      ? doc?.files?.map((file) => Document.factory(file))
      : [];
    this.photos = Array.isArray(doc?.photos)
      ? doc?.photos?.map((file) => Document.factory(file))
      : [];
    this.selected = isBoolean(doc?.selected) ? doc?.selected ?? false : false;
  }

  static factory(doc?: any): Budget | null {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Budget(doc);
  }

  static toFirebaseFormat(self: Partial<Budget>) {
    return {
      code: self?.code ?? null,
      selected: self?.selected ?? null,
      deliveredDate: fromDateToTimestamp(self?.deliveredDate),
      cost: self?.cost ?? null,
      files: self?.files?.map((file) => Document.toFirebaseFormat(file)),
      photos: self?.photos?.map((file) => Document.toFirebaseFormat(file)),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
