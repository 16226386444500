import firebase from '../client';

export async function generateClientId(): Promise<string> {
  const ref = firebase.firestore().collection('/counter').doc('/client');
  const counter = (await ref.get()).data();
  const current = Number(counter.counter);
  await ref.set({ counter: Number(current) + 1 }, { merge: true });
  return `C${current}`;
}

export async function generateSupplierId(): Promise<string> {
  const ref = firebase.firestore().collection('/counter').doc('/supplier');
  const counter = (await ref.get()).data();
  const current = Number(counter.counter);
  await ref.set({ counter: Number(current) + 1 }, { merge: true });
  return `P${current}`;
}

export async function generateProjectId(
  date: Date = new Date()
): Promise<string> {
  const year = String(date.getFullYear());
  let month = String(date.getMonth() + 1);
  if (month.length < 2) {
    month = `0${month}`;
  }
  const ref = firebase
    .firestore()
    .collection('/projectcounter')
    .doc(`/${year}`);
  const data = (await ref.get()).data();
  const months: Array<{ counter: number; id: string }> = data?.month ?? [];
  const idx = months?.findIndex((m) => m.id === month) ?? -1;
  let id: string;
  if (idx === -1) {
    months.push({
      id: month,
      counter: 1,
    });
    id = `${month}${year}.1`;
  } else {
    months[idx] = {
      ...months[idx],
      counter: (months[idx]?.counter ?? 0) + 1,
    };
    id = `${month}${year}.${months[idx].counter}`;
  }
  await firebase.firestore().collection('/projectcounter').doc(`/${year}`).set(
    {
      month: months,
    },
    { merge: true }
  );
  return id;
}
