import { Quote } from './Quote';
import { Budget } from './Budget';
import { Category } from './Category';
import { Subcategory } from './Subcategory';
import { FirebaseModel } from './FirebaseModel';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { fromDateToTimestamp } from '../lib/dateHandler';
import firebase from '../lib/firebase/client';

export interface PdfRowData {
  id?: string;
  quote?: Quote;
  budget?: Budget;
  category?: Category;
  subcategory?: Subcategory;
  cost?: number;
  quantity?: number;
  observations?: Array<PdfDetail>;
}

function createPdfRowData(row: any, project: any): PdfRowData {
  const requirement =
    project?.requirements?.find(
      (req) =>
        req?.quotes?.findIndex((_quote) => _quote?.id === row?.quote) !== -1
    ) ?? null;
  const quote =
    requirement?.quotes?.find((_quote) => _quote?.id === row?.quote) ?? null;
  const budget =
    quote?.budgets?.find((_budget) => _budget?.id === row?.budget) ?? null;
  const category = isString(requirement?.category)
    ? null
    : requirement?.category ?? null;
  const subcategory = isString(requirement?.subcategory)
    ? null
    : requirement?.subcategory ?? null;
  return {
    id: row?.id ?? null,
    cost: Number(row?.cost || '0'),
    quantity: Number(row?.quantity || '0'),
    quote,
    budget,
    category,
    subcategory,
    observations:
      row?.observations?.map((detail: any) =>
        createPdfDetail(detail, category)
      ) ?? [],
  };
}

export interface PdfDetail {
  id?: string;
  category?: Category;
  text?: string;
  cost?: number;
  quantity?: number;
  total?: number;
}

function createPdfDetail(detail: any, category: any): PdfDetail {
  return {
    id: detail?.id ?? '',
    category,
    text: detail?.text ?? '',
    cost: Number(detail?.cost || '0'),
    quantity: Number(detail?.quantity || '0'),
    total: Number(detail?.total || '0'),
  };
}

export class PdfModel extends FirebaseModel {
  version: number;

  data: PdfRowData[];

  constructor();

  constructor(doc: any, project: any);

  constructor(doc?: any, project?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined' || doc === null) {
      this.version = 0;
      this.data = [];
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.version = isNumber(doc?.version) ? doc?.version ?? 0 : 0;
    this.data = Array.isArray(doc?.data)
      ? doc?.data?.map((data) => createPdfRowData(data, project)) ?? []
      : [];
  }

  static factory(doc: any, project: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    if (typeof project === 'undefined' || project === null) return null;
    return new PdfModel(doc, project);
  }

  static toFirebaseFormat(self: Partial<PdfModel>) {
    return {
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
      version: isNumber(self?.version) ? Number(self?.version ?? null) : null,
      data: Array.isArray(self?.data)
        ? self?.data?.map((data) => ({
            id: data?.id ?? null,
            quote: data?.quote?.id ?? null,
            budget: data?.budget?.id ?? null,
            category: data?.category?.id ?? null,
            subcategory: data?.subcategory?.id ?? null,
            cost: data?.cost ?? null,
            quantity: data?.quantity ?? null,
            observations:
              data?.observations?.map((value) => ({
                ...value,
                category: value?.category?.id ?? null,
              })) ?? null,
          }))
        : null,
    };
  }

  static syncData(self: PdfModel, newRows: PdfRowData[]) {
    const oldRows = self?.data ?? [];
    const rows: PdfRowData[] = newRows.map((newRow) => {
      const oldRow =
        oldRows?.find(
          (_row) =>
            _row?.budget?.id === newRow?.budget?.id &&
            _row?.category?.id === newRow?.category?.id &&
            _row?.cost === newRow?.cost &&
            _row?.quantity === newRow?.quantity &&
            _row?.quote?.id === newRow?.quote?.id &&
            _row?.subcategory?.id === newRow?.subcategory?.id
        ) ?? null;
      return oldRow === null ? newRow : oldRow;
    });
    return rows;
  }

  static async update(self: Partial<PdfModel>) {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${self?.id}`)
      .set(
        {
          pdf: PdfModel.toFirebaseFormat(self),
        },
        { merge: true }
      );
  }
}
