import firebase from './firebase/client';

export function isString(doc: any): doc is string {
  return typeof doc === 'string';
}

export function isNumber(doc: any): doc is number {
  return typeof doc === 'number' || typeof doc === 'bigint';
}

export function isBoolean(doc: any): doc is boolean {
  return typeof doc === 'boolean';
}

export function isTimestamp(doc: any): doc is firebase.firestore.Timestamp {
  return (doc as firebase.firestore.Timestamp)?.toDate !== undefined;
}
