import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { Category } from './Category';
import { FirebaseModel } from './FirebaseModel';
import { Subcategory } from './Subcategory';
import { Supplier } from './Supplier';
import { Quote } from './Quote';
import { Document } from './Document';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class Requirement extends FirebaseModel {
  category?: string | Category;

  subcategory?: string | Subcategory;

  //! Mi pana acuerdate de que esto es la droga de Alberto
  description?: string;

  suppliers?: Array<string | Supplier>;

  emailSend?: Array<string>;

  quotes?: Array<Quote>;

  quantity?: number;

  files?: Array<Document>;

  photos?: Array<Document>;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.category = null;
      this.subcategory = null;
      this.description = '';
      this.emailSend = [];
      this.quotes = [];
      this.quantity = 0;
      this.files = [];
      this.photos = [];
    }
    this.id = isString(doc?.id) ? doc?.id : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.category = isString(doc?.category)
      ? doc?.category ?? null
      : Category.factory(doc?.category);
    this.subcategory = isString(doc?.subcategory)
      ? doc?.subcategory ?? null
      : Subcategory.factory(doc?.subcategory);
    this.description = isString(doc?.description) ? doc?.description : '';
    this.emailSend = Array.isArray(doc?.emailSend)
      ? doc?.emailSend?.map((email) => (isString(email) ? email : ''))
      : [];
    this.suppliers = Array.isArray(doc?.suppliers)
      ? doc?.suppliers?.map((supplier) =>
          isString(supplier) ? supplier : Supplier.factory(supplier)
        )
      : [];
    this.quotes = Array.isArray(doc?.quotes)
      ? doc?.quotes?.map((quote) => Quote.factory(quote))
      : [];
    this.files = Array.isArray(doc?.files)
      ? doc?.files?.map((file) => Document.factory(file))
      : [];
    this.photos = Array.isArray(doc?.photos)
      ? doc?.photos?.map((file) => Document.factory(file))
      : [];
    this.quantity = isNumber(doc?.quantity) ? doc?.quantity ?? null : null;
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Requirement(doc);
  }

  static toFirebaseFormat(self: Partial<Requirement>) {
    return {
      category: isString(self?.category)
        ? self?.category ?? null
        : self?.category?.id ?? null,
      subcategory: isString(self?.subcategory)
        ? self?.subcategory ?? null
        : self?.subcategory?.id ?? null,
      description: self?.description ?? null,
      emailSend: self?.emailSend ?? null,
      quantity: self?.quantity ?? null,
      quotes:
        self?.quotes?.map((quote) => Quote.toFirebaseFormat(quote)) ?? null,
      files:
        self?.files?.map((file) => Document.toFirebaseFormat(file)) ?? null,
      photos:
        self?.photos?.map((file) => Document.toFirebaseFormat(file)) ?? null,
      suppliers: self?.suppliers?.map((supplier) =>
        isString(supplier) ? supplier ?? null : supplier?.id ?? null
      ),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
