import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { Supplier } from './Supplier';
import { Budget } from './Budget';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class Quote extends FirebaseModel {
  supplier?: string | Supplier;

  selected?: boolean;

  favorite?: boolean;

  budgets?: Array<Budget>;

  observation?: string;

  budgetId?: string;

  requirementId?: string;

  multiplier?: number;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.supplier = null;
      this.selected = false;
      this.favorite = false;
      this.budgets = [];
      this.observation = '';
      this.budgetId = '';
      this.requirementId = '';
      this.multiplier = 1;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.budgets = Array.isArray(doc?.budgets)
      ? doc?.budgets?.map((b) => Budget.factory(b))
      : [];
    this.supplier = isString(doc?.supplier)
      ? doc?.supplier
      : Supplier.factory(doc?.supplier);
    this.selected = isBoolean(doc?.selected) ? doc?.selected ?? false : false;
    this.favorite = isBoolean(doc?.favorite) ? doc?.favorite ?? false : false;
    this.observation = isString(doc?.observation)
      ? doc?.observation ?? null
      : null;
    this.budgetId = isString(doc?.budgetId) ? doc?.budgetId ?? null : null;
    this.requirementId = isString(doc?.requirementId)
      ? doc?.requirementId ?? ''
      : '';
    this.multiplier = isNumber(doc?.multiplier) ? doc?.multiplier ?? 0 : 0;
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Quote(doc);
  }

  static toFirebaseFormat(self: Partial<Quote>) {
    return {
      supplier: isString(self?.supplier)
        ? self?.supplier ?? null
        : self?.supplier?.id ?? null,
      selected: self?.selected ?? null,
      favorite: self?.favorite ?? null,
      budgets: self?.budgets?.map((budget) => Budget.toFirebaseFormat(budget)),
      observation: self?.observation ?? null,
      budgetId: self?.budgetId ?? null,
      requirementId: self?.requirementId ?? null,
      multiplier: self?.multiplier ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
