import type firebase from 'firebase';
import { v4 as uuid } from 'uuid';

export class FirebaseModel {
  id?: string;

  active?: boolean;

  createdAt?: Date;

  updatedAt?: Date;

  constructor(
    id?: string,
    active = true,
    createdAt = new Date(),
    updatedAt = new Date()
  ) {
    this.id = id;
    if (typeof id === 'undefined') {
      this.id = uuid();
    }
    this.active = active;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export type FirebaseJSON = Record<string, JSONValue>;

type JSONValue =
  | string
  | number
  | boolean
  | firebase.firestore.Timestamp
  | JSONArray;

type JSONArray = Array<FirebaseJSON>;
