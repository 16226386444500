import firebase from '../lib/firebase/client';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { ContactPerson } from './ContactPerson';
import { Category } from './Category';
import { Subcategory } from './Subcategory';
import { Product } from './Product';
import { fromDateToTimestamp } from '../lib/dateHandler';
import { joinRif } from '../lib/formatRif';
import { generateSupplierId } from '../lib/firebase/operations/generateId';

export class Supplier extends FirebaseModel {
  code?: string;

  supplier?: string;

  companyName?: string;

  rif?: string;

  dni?: string;

  dniType?: string;

  address?: string;

  web?: string;

  instagram?: string;

  bio?: string;

  bankingInfo?: string;

  phone?: string;

  email?: string;

  categories?: Array<string | Category>;

  subcategories?: Array<string | Subcategory>;

  products?: Array<string | Product>;

  contactPeople?: Array<ContactPerson>;

  discount?: number;

  observation?: string;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.code = '';
      this.supplier = '';
      this.companyName = '';
      this.rif = '';
      this.dni = '';
      this.dniType = '';
      this.address = '';
      this.web = '';
      this.instagram = '';
      this.bio = '';
      this.bankingInfo = '';
      this.phone = '';
      this.email = '';
      this.observation = '';
      this.categories = [];
      this.subcategories = [];
      this.products = [];
      this.contactPeople = [];
      this.discount = 0;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.code = isString(doc?.code) ? doc?.code ?? null : null;
    this.supplier = isString(doc?.supplier) ? doc?.supplier ?? null : null;
    this.companyName = isString(doc?.companyName)
      ? doc?.companyName ?? null
      : null;
    this.rif = isString(doc?.rif) ? doc?.rif ?? null : null;
    this.dni = isString(doc?.dni) ? doc?.dni ?? null : null;
    this.dniType = isString(doc?.dniType) ? doc?.dniType ?? null : null;
    this.address = isString(doc?.address) ? doc?.address ?? null : null;
    this.web = isString(doc?.web) ? doc?.web ?? null : null;
    this.instagram = isString(doc?.instagram) ? doc?.instagram ?? null : null;
    this.bio = isString(doc?.bio) ? doc?.bio ?? null : null;
    this.bankingInfo = isString(doc?.bankingInfo)
      ? doc?.bankingInfo ?? null
      : null;
    this.phone =
      isString(doc?.phone) || isNumber(doc?.phone) ? String(doc?.phone) : null;
    this.email = isString(doc?.email) ? doc?.email ?? null : null;
    this.observation = isString(doc?.observation)
      ? doc?.observation ?? null
      : null;
    this.categories = Array.isArray(doc?.categories)
      ? doc?.categories?.map((category) =>
          isString(category) ? category : Category.factory(category)
        )
      : [];
    if (doc?.subCategories) {
      this.subcategories = Array.isArray(doc?.subCategories)
        ? doc?.subCategories?.map((subcategory) =>
            isString(subcategory)
              ? subcategory
              : Subcategory.factory(subcategory)
          )
        : [];
    } else {
      this.subcategories = Array.isArray(doc?.subcategories)
        ? doc?.subcategories?.map((subcategory) =>
            isString(subcategory)
              ? subcategory
              : Subcategory.factory(subcategory)
          )
        : [];
    }
    this.products = Array.isArray(doc?.products)
      ? doc?.products?.map((product) =>
          isString(product) ? product : Product.factory(product)
        )
      : [];
    this.contactPeople = Array.isArray(doc?.contactPeople)
      ? doc?.contactPeople
          ?.map((person) =>
            isString(person) ? null : ContactPerson.factory(person)
          )
          ?.filter((x) => !!x)
      : [];
    this.discount = isNumber(doc?.discount) ? doc?.discount ?? 0 : 0;
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    if (doc instanceof Supplier) return doc;
    return new Supplier(doc);
  }

  static toFirebaseFormat(self: Partial<Supplier>) {
    return {
      code: self?.code ?? null,
      supplier: self?.supplier ?? null,
      companyName: self?.companyName ?? null,
      rif: self?.rif ?? null,
      dni: self?.dni ?? null,
      dniType: self?.dniType ?? null,
      address: self?.address ?? null,
      web: self?.web ?? null,
      instagram: self?.instagram ?? null,
      bio: self?.bio ?? null,
      bankingInfo: self?.bankingInfo ?? null,
      phone: self?.phone ?? null,
      email: self?.email ?? null,
      observation: self?.observation ?? null,
      categories: self?.categories?.map((category) =>
        isString(category) ? category ?? null : category?.id ?? null
      ),
      subcategories: self?.subcategories?.map((subcategory) =>
        isString(subcategory) ? subcategory ?? null : subcategory?.id ?? null
      ),
      products: self?.products?.map((product) =>
        isString(product) ? product ?? null : Product.toFirebaseFormat(product)
      ),
      contactPeople:
        self?.contactPeople?.map((person) =>
          ContactPerson.toFirebaseFormat(person)
        ) ?? null,
      discount: self?.discount ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async create(state: any): Promise<Supplier> {
    const id = await generateSupplierId();
    const _rif = joinRif(state?.rif, state?.dniType);
    const supplier: Supplier = {
      id,
      supplier: state?.supplier ?? '',
      companyName: state?.companyName ?? '',
      rif: _rif,
      address: state?.address ?? '',
      web: state?.web ?? '',
      instagram: state?.instagram ?? '',
      contactPeople:
        state?.contactPeople?.map((person) =>
          ContactPerson.toFirebaseFormat(person)
        ) ?? [],
      phone: state?.phone ?? '',
      email: state?.contactPeople?.[0]?.email ?? '',
      bio: state?.bio ?? '',
      bankingInfo: state?.bankingInfo ?? '',
      discount: Number(state?.discount ?? '0'),
      products:
        state?.products?.map((product) => Product.toFirebaseFormat(product)) ??
        [],
      categories:
        state?.categories?.map((category) =>
          isString(category) ? category : Category.toFirebaseFormat(category)
        ) ?? [],
      subcategories:
        state?.subcategories?.map((subcategory) =>
          isString(subcategory)
            ? subcategory
            : Subcategory.toFirebaseFormat(subcategory)
        ) ?? [],
    };
    await firebase
      .firestore()
      .collection('/suppliers')
      .doc(id)
      .set(supplier, { merge: true });
    return supplier;
  }

  static async update(state: any, id: string): Promise<string> {
    const _rif = joinRif(state?.rif, state?.dniType);
    await firebase
      .firestore()
      .collection('/suppliers')
      .doc(id)
      .set(
        {
          id,
          supplier: state?.supplier ?? '',
          companyName: state?.companyName ?? '',
          rif: _rif,
          address: state?.address ?? '',
          web: state?.web ?? '',
          instagram: state?.instagram ?? '',
          contactPeople:
            state?.contactPeople?.map((person) =>
              ContactPerson.toFirebaseFormat(person)
            ) ?? [],
          phone: state?.phone ?? '',
          email: state?.contactPeople?.[0]?.email ?? '',
          bio: state?.bio ?? '',
          bankingInfo: state?.bankingInfo ?? '',
          discount: Number(state?.discount ?? '0'),
          products:
            state?.products?.map((product) =>
              Product.toFirebaseFormat(product)
            ) ?? [],
          categories:
            state?.categories?.map((category) =>
              isString(category)
                ? category
                : Category.toFirebaseFormat(category)
            ) ?? [],
          subcategories:
            state?.subcategories?.map((subcategory) =>
              isString(subcategory)
                ? subcategory
                : Subcategory.toFirebaseFormat(subcategory)
            ) ?? [],
        },
        { merge: true }
      );
    return id;
  }

  static async delete(supplier: Partial<Supplier>): Promise<void> {
    await firebase
      .firestore()
      .collection('/suppliers')
      .doc(`/${supplier?.id}`)
      .delete();
  }

  static async deleteMany(ids: string[]): Promise<void> {
    const batch = firebase.firestore().batch();
    ids.forEach((id) => {
      batch.delete(firebase.firestore().collection('/suppliers').doc(`/${id}`));
    });
    await batch.commit();
  }
}
