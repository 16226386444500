export function joinRif(rif: string, rifType: string): string {
  if (
    typeof rif === 'undefined' ||
    rif === null ||
    typeof rifType === 'undefined' ||
    rifType === null
  ) {
    return null;
  }
  if (rifType === 'none') {
    // eslint-disable-next-line no-param-reassign
    rifType = '';
  }
  if (rif.trim().length === 0) {
    return '';
  }
  return `${rifType ?? ''}-${rif
    ?.trim()
    ?.substring(0, rif?.length - 1)}-${rif?.trim()?.charAt(rif?.length - 1)}`;
}

export function splitRif(rif: string): [rif: string, rifType: string] {
  if (typeof rif === 'undefined' || rif === null) {
    return null;
  }
  if (rif?.trim()?.length === 0) {
    return ['', 'none'];
  }
  const _rif = rif.replaceAll('-', '').trim();
  const dniType = Number.isNaN(Number(rif.charAt(0))) ? rif.charAt(0) : 'none';
  return [_rif.substring(1), dniType];
}
