import firebase from '../lib/firebase/client';
import { fromDateToTimestamp } from '../lib/dateHandler';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { Category } from './Category';
import { FirebaseModel } from './FirebaseModel';

export class Subcategory extends FirebaseModel {
  name?: string;

  category?: string | Category;

  showOnBloop?: boolean;

  order?: number;

  selected?: boolean;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.id = null;
      this.name = '';
      this.category = null;
      this.showOnBloop = false;
      this.order = null;
      this.selected = null;
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active ?? null : true;
    this.selected = null;
    this.showOnBloop = isBoolean(doc?.showOnBloop)
      ? doc?.showOnBloop ?? null
      : false;
    this.order = isNumber(doc?.order) ? Number(doc?.order ?? '-1') : -1;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.name = isString(doc?.name) ? doc?.name ?? null : null;
    this.category = isString(doc?.category)
      ? doc?.category ?? ''
      : Category.factory(doc?.category);
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Subcategory(doc);
  }

  static toFirebaseFormat(self: Partial<Subcategory>) {
    return {
      name: self?.name ?? null,
      category: isString(self?.category)
        ? self?.category ?? null
        : self?.category?.id ?? null,
      showOnBloop: self?.showOnBloop ?? null,
      order: self?.order ?? null,
      selected: self?.selected ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async create(subcategory: Partial<Subcategory>): Promise<Subcategory> {
    const ref = await firebase
      .firestore()
      .collection('/subcategories')
      .add({
        ...(Subcategory.toFirebaseFormat(subcategory) ?? {}),
        id: '',
      });
    await ref.update({
      ...(Subcategory.toFirebaseFormat(subcategory) ?? {}),
      id: ref.id,
    });
    return Subcategory.factory({
      ...(Subcategory.toFirebaseFormat(subcategory) ?? {}),
      id: ref.id,
    });
  }

  static async update(subcategory: Partial<Subcategory>): Promise<void> {
    await firebase
      .firestore()
      .collection('/subcategories')
      .doc(`/${subcategory?.id}`)
      .set(
        {
          ...(Subcategory.toFirebaseFormat(subcategory) ?? {}),
        },
        { merge: true }
      );
  }

  static async delete(subcategory: Partial<Subcategory>): Promise<void> {
    await firebase
      .firestore()
      .collection('/subcategories')
      .doc(`/${subcategory?.id}`)
      .delete();
  }
}
