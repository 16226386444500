import { v4 as uuid } from 'uuid';
import { fromDateToTimestamp } from '../lib/dateHandler';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';

export class ContactPerson extends FirebaseModel {
  id?: string;

  firstName?: string;

  lastName?: string;

  email?: string;

  phone?: string;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc.id) ? doc.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.id = uuid();
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
    }
    this.id = isString(doc?.id) ? doc?.id ?? null : null;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.firstName = isString(doc?.firstName) ? doc?.firstName ?? null : null;
    this.lastName = isString(doc?.lastName) ? doc?.lastName ?? null : null;
    this.email = isString(doc?.email) ? doc?.email ?? null : null;
    this.phone =
      isString(doc?.phone) || isNumber(doc?.phone)
        ? String(doc?.phone) ?? null
        : null;
  }

  static getFullName(self: Partial<ContactPerson>): string {
    if (!self?.firstName && !self?.lastName) return 'N/A';
    return `${self?.firstName ?? ''} ${self?.lastName ?? ''}`.trim();
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new ContactPerson(doc);
  }

  static toFirebaseFormat(self: Partial<ContactPerson>) {
    return {
      firstName: self?.firstName ?? null,
      lastName: self?.lastName ?? null,
      email: self?.email ?? null,
      phone: self?.phone ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }
}
