import dayjs from 'dayjs';
import 'dayjs/locale/es';
import firebase from './firebase/client';

dayjs.locale('es');

type Timestamp = firebase.firestore.Timestamp;

export function isDate(date: Date | Timestamp): date is Date {
  return (date as Date)?.toISOString !== undefined;
}

export function isTimestamp(date: Date | Timestamp): date is Timestamp {
  return (date as Timestamp)?.toDate !== undefined;
}

export function formatToString(
  date: string | Date | Timestamp,
  format = 'YYYY-MM-DD'
) {
  if (date === null || date === undefined) {
    return '-';
  }
  if (typeof date === 'string') {
    return date;
  }
  if (isDate(date)) {
    return dayjs(date)?.format(format);
  }
  if (isTimestamp(date)) {
    return dayjs(date.toDate())?.format(format);
  }
  return '';
}

export function fromStringToDate(date: string): Date {
  if (typeof date === 'undefined' || date === null) return null;
  if (date === '') return new Date();
  const [year, month, day] = date.split('-');
  return new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  );
}

export function fromTimestampToDate(date: Timestamp): Date {
  if (typeof date === 'undefined' || date === null) return null;
  if (isTimestamp(date)) {
    return date.toDate();
  }
  return new Date();
}

export function fromDateToTimestamp(date: Date): Timestamp {
  if (typeof date === 'undefined' || date === null) return null;
  let _date = new Date();
  if (isDate(date)) {
    _date = date;
  }
  return firebase.firestore.Timestamp.fromDate(_date);
}
